import React from 'react';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export const ThankYou = ({show, message, links, cta = null, bottomMessage = null}) => (
    <div className={`${!show && 'Hide'}`}>
        <div dangerouslySetInnerHTML={{__html: message}}></div>
        {cta ? <Button className="Top-margin" size="lg" variant="primary" href={cta.link}>{cta.button}</Button> : null}
        <div dangerouslySetInnerHTML={{__html: bottomMessage}}></div>
        <Row className="Help-row">
            {links.map((link) => (
                <Col>
                    <div className="Help-text">{link.text}</div>
                    <div className="Help-link"><Button size="lg" variant="primary" href={link.link}>{link.button}</Button></div>
                </Col>
            ))}
        </Row>
    </div>
);