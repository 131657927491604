import React from 'react';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import Button from 'react-bootstrap/Button';

export const Modal = ( {show}) => (
    <Popup open={show} modal>
        <center>
            <div>We encourage you to log into MyAccount to file a service request with your home warranty so we can inspect your current system and review your options with you.</div>
            <br/><br/>
            <div><b>CREATE YOUR SERVICE REQUEST NOW.</b></div>
            <Button size="lg" variant="primary" href="https://www.ahs.com/my-account/pages/register.jsp/customer=true">Log into MyAccount</Button>
        </center>
    </Popup>
);