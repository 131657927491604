import React from "react";

export const Timeframe = ({ name, value, handleChange, timeframes, show }) => (
    <div className={`${!show && 'Hide'} Top-margin`}>
        <div className='Text-left'><b>When are you interested in upgrading to a new HVAC system?</b></div>
        <div className="Text-left">Quotes and equipment availability are valid for 30 days. To avoid multiple Pro visits to your home, please select ”within 30 days” if you’re ready to move forward.</div>
        <div onChange={handleChange} className="Top-padding">
            {Object.entries(timeframes).map(([key, value]) => (
                <div className="radio" key={key}>
                    <label key={key}>
                        <input key={key} type="radio" name={name} value={key} />
                        {value}
                    </label>
                </div>
            ))}
        </div>
        <div className="Top-padding"></div>
        <div className="Text-left"><b>Is your Air Conditioning / Heat currently working?</b></div>
        <div onChange={handleChange} className="Top-padding">
            <div className="radio" key="yes">
                <label key="yes">
                    <input key="yes" type="radio" name="working" value="yes" />
                    Yes
                </label>
            </div>
            <div className="radio" key="no">
                <label key="no">
                    <input key="no" type="radio" name="working" value="no" />
                    No
                </label>
            </div>
        </div>
    </div>
);