import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import { Component } from "react"

class UserForm extends Component {
    constructor() {
        super();
        this.state = {
            fields: {},
            errors: {},
            showModal: false,
        }
    }

    setValue = (event) => {
        let fields = this.state.fields;
        fields[event.target.name] = event.target.value;
        this.setState({ fields });
        this.props.setValue(event);
    }

    validate = (event) => {
        let errors = {};
        if (!this.state.fields["name"]) {
          errors["name"] = "Required";
        }
        if (!this.state.fields["phone"]) {
            errors["phone"] = "Required";
        }
        if (!this.state.fields["address"]) {
            errors["address"] = "Required";
        }
        if (!this.state.fields["city"]) {
            errors["city"] = "Required";
        }
        if (!this.state.fields["state"]) {
            errors["state"] = "Required";
        }
        if (!this.state.fields["zip"]) {
            errors["zip"] = "Required";
        }
        if (this.props.agent && !this.state.fields["contractId"]) {
            errors["contractId"] = "Required";
        }
        if (!this.state.fields["email"]) {
            errors["email"] = "Required";
        }
        this.setState({ errors: errors });
        if (Object.keys(errors).length === 0) {
            this.props.submit(event);
        }
    }

    showModal = (event) => {
        this.setState({ "showModal": true});
    }

    render() {
        let contractId = null;
        if (this.props.agent) {
            contractId = <div className="Input">
                <Row>
                    <Col>Contract Id*:</Col>
                    <Col><input name="contractId" onChange={this.setValue} value={this.state.fields["contractId"]}/><span style={{ color: "red" }}>  {this.state.errors["contractId"]}</span></Col>
                </Row>
            </div>;
        }
        return (
            <div className={`${!this.props.show && 'Hide'} Text-left Top-margin`}>
                <div dangerouslySetInnerHTML={{__html: this.props.message}}></div>
                <div className="Top-margin"></div>
                {contractId}
                <div className="Input">
                    <Row>
                        <Col>Name*:</Col>
                        <Col><input name="name" onChange={this.setValue} value={this.state.fields["name"]}/><span style={{ color: "red" }}>  {this.state.errors["name"]}</span></Col>
                    </Row>
                </div>
                <div className="Input">
                    <Row>
                        <Col>Phone*:</Col>
                        <Col><input name="phone" onChange={this.setValue} value={this.state.fields["phone"]}/><span style={{ color: "red" }}>  {this.state.errors["phone"]}</span></Col>
                    </Row>
                </div>
                <div className="Input">
                    <Row>
                        <Col>Property Address*:</Col>
                        <Col><input name="address" onChange={this.setValue} value={this.state.fields["address"]}/><span style={{ color: "red" }}>  {this.state.errors["address"]}</span></Col>
                    </Row>
                </div>
                <div className="Input">
                    <Row>
                        <Col>Property City*:</Col>
                        <Col><input name="city" onChange={this.setValue} value={this.state.fields["city"]}/><span style={{ color: "red" }}>  {this.state.errors["city"]}</span></Col>
                    </Row>
                </div>
                <div className="Input">
                    <Row>
                        <Col>Property State*:</Col>
                        <Col><input name="state" onChange={this.setValue} value={this.state.fields["state"]}/><span style={{ color: "red" }}>  {this.state.errors["state"]}</span></Col>
                    </Row>
                </div>
                <div className="Input">
                    <Row>
                        <Col>Property Zip*:</Col>
                        <Col><input name="zip" onChange={this.setValue} value={this.state.fields["zip"]}/><span style={{ color: "red" }}>  {this.state.errors["zip"]}</span></Col>
                    </Row>
                </div>
                <div className="Input">
                    <Row>
                        <Col>Email Address*:</Col>
                        <Col><input name="email" onChange={this.setValue} value={this.state.fields["email"]}/><span style={{ color: "red" }}>  {this.state.errors["email"]}</span></Col>
                    </Row>
                </div>
                <Button size="lg" variant="primary" onClick={this.validate}>Next</Button>
            </div>
        )
    }
}

export default UserForm;