import React from 'react';
import { Component } from "react"

class ServiceRequest extends Component {
    constructor() {
        super();
        this.state = {
            fields: {},
            errors: {},
        }
    }

    render() {
        return (
            <div className={`${!this.props.show && 'Hide'} Text-left Top-margin`}>
                <div>
                    Do you have an open service request for your non-working HVAC?
                </div>
                <div className="Top-margin"></div>
                <div onChange={this.props.handleChange} className="Top-padding">
                    {Object.entries(this.props.options).map(([key, value]) => (
                        <div className="radio" key={key}>
                            <label key={key}>
                                <input key={key} type="radio" name={this.props.name} value={key} />
                                {value}
                            </label>
                        </div>
                    ))}
                </div>
            </div>
        )
    }
}

export default ServiceRequest;