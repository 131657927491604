import React from 'react';

export const Footer = ({ policyLink, terms, addStyle, brand, disclaimer }) => (
    <footer>
        <div className="Footer">
            {addStyle}
            <div className={"Footer-bottom " + (addStyle ? "Footer-bottom-ahs" : null)}>
                <div className={"Footer-margin " + (addStyle ? "Footer-margin-ahs" : null)}>
                    <a href={policyLink} tabIndex="0" target="_blank" rel="nofollow noopener noreferrer" aria-disabled="false">Privacy Policy</a>{ terms? <a href={terms} tabIndex="0" target="_blank" rel="nofollow noopener noreferrer" aria-disabled="false">Terms</a> : null}
                    <div className="Footer-terms">
                        Limitations and exclusions apply. The HVAC Upgrade Program is serviced and fulfilled by Frontdoor Pro. Service may not be available in all areas.
                    </div>
                    <div className="Footer-terms">
                        *Contractor pricing and amount of savings off national retail pricing will vary based on location and equipment availability.
                    </div>
                    <div className="Footer-terms">
                        **Energy savings are from blended {brand} markets and were obtained via a third-party cost calculator (www.seerenergysavings.com). Savings vary based on location, system tonnage and SEER.
                    </div>
                    <div className="Footer-terms">
                        †Financing provided by an independent financing company not affiliated with {disclaimer} or Frontdoor Pro.
                    </div>
                </div>
            </div>
        </div>
    </footer>
);