import hsaLogo from './hsa.png';
import ahsLogo from './ahs.png';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { Timeframe } from './components/Timeframe.js';
import { Headline } from './components/Headline.js';
import UserForm from './components/UserForm.js';
import { ThankYou } from './components/ThankYou.js';
import { Footer } from './components/Footer.js';
import { Modal } from './components/Modal.js';
import ServiceRequest from './components/ServiceRequest.js';
import React, { Component } from 'react';
import axios from 'axios';
import Container from 'react-bootstrap/Container';
import LoadingOverlay from 'react-loading-overlay-ts';
import { format } from 'react-string-format';

const prodApiUrl = "https://services.messagegears.io/form/form-submit?fm=HVAC";
const localApiUrl = "http://127.0.0.1:8084/form/form-submit?fm=HVAC";
const timeframes = {
  0: "Within 30 days",
  1: "Longer than 30 days",
  2: "Not sure"
}
const serviceRequestOptions = {
  0: "Yes",
  1: "No", 
  2: "I don't have HVAC coverage."
}
const query = new URLSearchParams(window.location.search);
const hsaLinks = {
  specialOffers: "https://myaccount.myhomewarranty.com/hb/special-offers/108080?contractId={0}&zipCode={1}",
  hvacInspection: "https://myaccount.myhomewarranty.com/hb/diy/7073?contractId={0}&zipCode={1}",
  howLong: "https://myaccount.myhomewarranty.com/hb/diy/7074?contractId={0}&zipCode={1}",
  myAccount: "https://myaccount.myhomewarranty.com/login",
  policy: "https://www.onlinehsa.com/privacy-policy/",
  terms: null
}
const ahsLinks = {
  specialOffers: "https://myaccount.ahs.com/hb/special-offers/107979?contractId={0}&zipCode={1}",
  hvacInspection: "https://myaccount.ahs.com/hb/diy/7073?contractId={0}&zipCode={1}",
  howLong: "https://myaccount.ahs.com/hb/diy/7074?contractId={0}&zipCode=",
  myAccount: "https://www.ahs.com/my-account/pages/register.jsp/customer=true",
  policy: "https://www.ahs.com/privacy-policy",
  terms: "https://www.ahs.com/terms-of-use/"
}

class App extends Component {
  
  constructor() {
    super();
    this.state = {
      loading: false,
      email: null,
      showForm: false,
      showMenu: true,
      showThankYou: false,
      timeframe: 0,
      showHeadline: true,
      headline: "Welcome! Thank you for your interest in the New HVAC Program",
      subhead: "With this program you can save thousands* off national retail prices and improve the efficiency of your home. Use the form below to schedule your free in-home quote with one of our HVAC professionals.",
      message: null,
      name: null,
      phone: null,
      address: null,
      city: null,
      state: null,
      zip: null,
      source: null,
      campaign: 0,
      links: [],
      working: null,                     
      brand: "HSA℠",
      logo: hsaLogo,                              
      showModal: false,
      showServiceRequest: false,
      openServiceRequest: null,
      userFormMessage: "Please complete the form below so we, our affiliates and our Pros can contact you and provide you with a FREE in-home quote.  For more information, see our  <a href=\"https://www.ahs.com/privacy-policy\">Privacy Policy</a> and <a href=\"https://www.ahs.com/terms-of-use\">Terms of Use.",
      cta: null,
      bottomMessage: null,
      linkObj: hsaLinks,
      addStyle: false, 
      footerBrand: "HSA",
      footerDisclaimer: "Home Security of America",
      apiUrl: prodApiUrl,
      agent: false,
      agentId: null, 
      contractId: null,
      zipCode: null
    }
  }

  componentDidMount() {
    const hostName = window.location.hostname;
    document.title = "HSA - HVAC";
    if (hostName === "localhost" || hostName === "127.0.0.1") {
      this.setState({ "apiUrl": localApiUrl });
    }
    this.setState({ "email": query.get("email") });
    const cId = query.get("cid");
    if (cId) {
      this.setState({ "campaign": query.get("cid") });
    }
    const source = query.get("source");
    if (source) {
      this.setState({ "source": source });
    } else {
      this.setState({ "source": document.referrer });
    }
    if (source && source.includes("ahs")) {
      this.setState({ "brand": "American Home Shield®" });
      this.setState({ "footerBrand": "AHS"});
      document.title = "AHS - HVAC";
      this.setState({ "footerDisclaimer": "American Home Shield" });
      this.setState({ "logo": ahsLogo });
      this.setState({ "linkObj": ahsLinks });
      this.setState({ "addStyle": true });
      this.setState({ "links": [{
        link: this.state.linkObj.howLong,
        button: "Go Back to MyAccount",
        text: ""
      }]});
    }
    const agent = query.get("agent");
    if (agent) {
      this.setState({ "agent": true });
      this.setState({ "agentId": agent });
    }
    const contractId = query.get('contractId');
    if (contractId) {
      this.setState({ "contractId": contractId });
    }
    const zipCode = query.get('zipCode');
    if (zipCode) {
      this.setState({ "zip": zipCode });
    }
  }

  formPost = () => {
    this.setState({ "message": "<h1>THANK YOU!</h1>The " + this.state.brand + " New HVAC Program is powered by Frontdoor Pro®. One of their trusted, independent service contractors will contact you within 2 business days to schedule your FREE in-home HVAC upgrade quote."});
    this.post();
  }

  post = () => {
    this.setState({ "loading": true});
    this.setState({ "links": [{
      link: format(this.state.linkObj.specialOffers, this.state.contractId, this.state.zip),
      button: "What to Expect",
      text: ""
    }]});
    const url = new URL(this.state.apiUrl);
    url.searchParams.set('email', this.state.email);
    url.searchParams.set('cId', this.state.campaign);
    url.searchParams.set('name', this.state.name);
    url.searchParams.set('address', this.state.address);
    url.searchParams.set('phone', this.state.phone);
    url.searchParams.set('city', this.state.city);
    url.searchParams.set('state', this.state.state);
    url.searchParams.set('zip', this.state.zip);
    url.searchParams.set('source', this.state.source);
    url.searchParams.set('timeframe', timeframes[this.state.timeframe]);
    url.searchParams.set('servicerequest', serviceRequestOptions[this.state.openServiceRequest]);
    url.searchParams.set('working', this.state.working);
    url.searchParams.set('contractId', this.state.contractId);
    url.searchParams.set('agent', this.state.agentId);
    axios.post(url.toString()).then((response) => {
      this.setState({ "showForm": false });
      this.setState({ "showHeadline": false });
      if (this.state.openServiceRequest) {
        switch (this.state.openServiceRequest) {
          case "0":
            this.setState({ "message": "Thanks for letting us know. We'll be in contact within 2 business days to follow up.<br/><br/><b>PRO TIP:</b> If your current system has a covered issue, you could potentially qualify for a home service plan credit on top of our discounted HVAC equipment pricing."});
            this.setState({ "links": [{
              link: format(this.state.linkObj.specialOffers, this.state.contractId, this.state.zip),
              button: "Learn More",
              text: ""
            }]});
            break;
          case "1":
            switch (this.state.timeframe) {
              case "0": 
                this.setState({ "showModal": true });
                this.setState({ "message": "<h1>THANK YOU!</h1>The " + this.state.brand + " New HVAC Program is powered by Frontdoor Pro®. One of their trusted, independent service contractors will contact you within 2 business days to schedule your FREE in-home HVAC upgrade quote."});
                break;
              case "1":
                this.setState( {"bottomMessage": "<b>PLEASE NOTE:</b><br/><br/>Upgrade quotes and equipment availability are valid for 30 days, so we encourage you to submit a quote request when you’re ready to move forward.<br/><br/>In the meantime, check out these helpful resources to learn more about the New HVAC Program, as well as general HVAC maintenance tips and tricks."})
                this.setState({ "cta": {
                  link: format(this.state.linkObj.myAccount, this.state.contractId, this.state.zip),
                  button: "Log into MyAccount"
                }})
                this.setState({ "message": "We encourage you to log into MyAccount to file a service request with your home warranty so we can inspect your current system and review your options with you.<br/><br/><b>CREATE YOUR SERVICE REQUEST NOW."});
                this.setState({ "links": [
                  {
                    link: format(this.state.linkObj.specialOffers, this.state.contractId, this.state.zip),
                    button: "Learn More",
                    text: "How does the New HVAC Program work?"
                  },
                  {
                    link: format(this.state.linkObj.hvacInspection, this.state.contractId, this.state.zip),
                    button: "Read More",
                    text: "How do you conduct a DIY HVAC inspection?"
                  },
                  {
                    link: format(this.state.linkObj.howLong, this.state.contractId, this.state.zip),
                    button: "Read More",
                    text: "How long do HVAC systems last?"
                  }
                ]});
                break;
              case "2": 
                this.setState( {"bottomMessage": "<b>PLEASE NOTE:</b><br/><br/>Upgrade quotes and equipment availability are valid for 30 days, so we encourage you to submit a quote request when you’re ready to move forward.<br/><br/>In the meantime, check out these helpful resources to learn more about the New HVAC Program, as well as general HVAC maintenance tips and tricks."})
                this.setState({ "cta": {
                  link: format(this.state.linkObj.myAccount, this.state.contractId, this.state.zip),
                  button: "Log into MyAccount"
                }})
                this.setState({ "message": "We encourage you to log into MyAccount to file a service request with your home warranty so we can inspect your current system and review your options with you.<br/><br/><b>CREATE YOUR SERVICE REQUEST NOW."});
                this.setState({ "links": [
                  {
                    link: format(this.state.linkObj.specialOffers, this.state.contractId, this.state.zip),
                    button: "Learn More",
                    text: "How does the New HVAC Program work?"
                  },
                  {
                    link: format(this.state.linkObj.hvacInspection, this.state.contractId, this.state.zip),
                    button: "Read More",
                    text: "How do you conduct a DIY HVAC inspection?"
                  },
                  {
                    link: format(this.state.linkObj.howLong, this.state.contractId, this.state.zip),
                    button: "Read More",
                    text: "How long do HVAC systems last?"
                  }
                ]});
                break;
              default:
                this.setState({ "message": "<h1>THANK YOU!</h1>The " + this.state.brand + " New HVAC Program is powered by Frontdoor Pro®. One of their trusted, independent service contractors will contact you within 2 business days to schedule your FREE in-home HVAC upgrade quote."});
                this.setState({ "links": [
                  {
                    link: format(this.state.linkObj.specialOffers, this.state.contractId, this.state.zip),
                    button: "Learn More",
                    text: "How does the New HVAC Program work?"
                  },
                  {
                    link: format(this.state.linkObj.hvacInspection, this.state.contractId, this.state.zip),
                    button: "Read More",
                    text: "How do you conduct a DIY HVAC inspection?"
                  },
                  {
                    link: format(this.state.linkObj.howLong, this.state.contractId, this.state.zip),
                    button: "Read More",
                    text: "How long do HVAC systems last?"
                  }
                ]});
                break;
            }
            break;
            case "2": 
              if (this.state.timeframe === "2") {
                this.setState({ "message": "<h1>THANK YOU FOR YOUR INTEREST.</h1></br>Upgrade quotes and equipment availability are valid for 30 days, so we encourage you to submit a quote request when you’re ready to move forward.<br/><br/>In the meantime, check out these helpful resources to learn more about the New HVAC Program, as well as general HVAC maintenance tips and tricks." });
                this.setState({ "links": [
                  {
                    link: format(this.state.linkObj.specialOffers, this.state.contractId, this.state.zip),
                    button: "Learn More",
                    text: "How does the New HVAC Program work?"
                  },
                  {
                    link: format(this.state.linkObj.hvacInspection, this.state.contractId, this.state.zip),
                    button: "Read More",
                    text: "How do you conduct a DIY HVAC inspection?"
                  },
                  {
                    link: format(this.state.linkObj.howLong, this.state.contractId, this.state.zip),
                    button: "Read More",
                    text: "How long do HVAC systems last?"
                  }
                ]});
              } else {
                this.setState({ "message": "<h1>THANK YOU!</h1>The " + this.state.brand + " New HVAC Program is powered by Frontdoor Pro®. One of their trusted, independent service contractors will contact you within 2 business days to schedule your FREE in-home HVAC upgrade quote."});
              }
              break;
          default:
            this.setState({ "message": "<h1>THANK YOU!</h1>The " + this.state.brand + " New HVAC Program is powered by Frontdoor Pro®. One of their trusted, independent service contractors will contact you within 2 business days to schedule your FREE in-home HVAC upgrade quote."});
        }
      } else if(this.state.timeframe === "0") {
        if (this.state.working === "yes") {
          this.setState({ "message": "<h1>THANK YOU!</h1>The " + this.state.brand + " New HVAC Program is powered by Frontdoor Pro®. One of their trusted, independent service contractors will contact you within 2 business days to schedule your FREE in-home HVAC upgrade quote."});
        }
      } else {
        this.setState({ "message": "<h1>THANK YOU FOR YOUR INTEREST.</h1></br>Upgrade quotes and equipment availability are valid for 30 days, so we encourage you to submit a quote request when you’re ready to move forward.<br/><br/>In the meantime, check out these helpful resources to learn more about the New HVAC Program, as well as general HVAC maintenance tips and tricks." });
        this.setState({ "links": [
          {
            link: format(this.state.linkObj.specialOffers, this.state.contractId, this.state.zip),
            button: "Learn More",
            text: "How does the New HVAC Program work?"
          },
          {
            link: format(this.state.linkObj.hvacInspection, this.state.contractId, this.state.zip),
            button: "Read More",
            text: "How do you conduct a DIY HVAC inspection?"
          },
          {
            link: format(this.state.linkObj.howLong, this.state.contractId, this.state.zip),
            button: "Read More",
            text: "How long do HVAC systems last?"
          }
        ]});
      }
      this.setState({ "showThankYou": true });
      this.setState({ "loading": false});
    }).catch(error => {
      console.log(error);
    })
  }

  setServiceRequest = (event) => {
    const value = event.target.value;
    this.setState({ [event.target.name]: value} );
    this.setState( {"showServiceRequest": false} );
    if (this.state.timeframe === "0" && value === "2") {
      this.setState( {"userFormMessage": "<b>SOUNDS LIKE AN HVAC UPGRADE COULD BE A GREAT OPTION.</b><br/><br/>Enter your information to get started:"})
      this.setState ( {"showForm": true} );
    } else {
      this.post();
    }
  }

    setTimeframe = (event) =>  {
      this.setState({ [event.target.name]: event.target.value }, () => {
        if (this.state.timeframe && this.state.working) {
          this.setState({ "showMenu": false});
          if (this.state.timeframe === "0" && this.state.working === "yes") {
            this.setState({ "showForm": true });
          } else if (this.state.working === "no") {
            this.setState({ "showServiceRequest" : true});
          } else {
            this.post();
          }
        }
      });
    }
    setValue = (event) => {
      this.setState({ [event.target.name]: event.target.value });
    }

    render() {
      return (
        <div className="App">
          <LoadingOverlay
            active={this.state.loading}
            spinner
            text="Submitting..."
          >
            <header className={(this.state.addStyle ? "Footer-bottom-ahs" : null)}>
                <img src={this.state.logo} className="App-logo" alt="logo" />
            </header>
            <Container>
            <Headline show={this.state.showHeadline} headline={this.state.headline} subhead={this.state.subhead}></Headline>
              <div className="Max-width">
                <Modal show={this.state.showModal}></Modal>
                <Timeframe name="timeframe" value={this.state.timeframe} timeframes={timeframes} handleChange={this.setTimeframe} show={this.state.showMenu}></Timeframe>
                <UserForm show={this.state.showForm} setValue={this.setValue} submit={this.post} agent={this.state.agent} message={this.state.userFormMessage}></UserForm>
                <ServiceRequest name="openServiceRequest" show={this.state.showServiceRequest} options={serviceRequestOptions} handleChange={this.setServiceRequest}></ServiceRequest>
                <ThankYou show={this.state.showThankYou} message={this.state.message} buttonText={this.state.buttonText} links={this.state.links} cta={this.state.cta} bottomMessage={this.state.bottomMessage}></ThankYou>
              </div>
            </Container>
            <Footer policyLink={this.state.linkObj.policy} terms={this.state.linkObj.terms} addStyle={this.state.addStyle} brand={this.state.footerBrand} disclaimer={this.state.footerDisclaimer}></Footer>
          </LoadingOverlay>
        </div>
      );
  }
}

export default App;
